$('.slider').slider({interval: 4000});
$('.dropdown-trigger').dropdown({constrainWidth: false});
$('.collapsible').collapsible();

$(window).on('resize', resizeCarousel);

function resizeCarousel() {
    $('.slider').each(function () {
        var currentElement = $(this);
	currentElement.css('width', 'auto');
        var bg = currentElement.find('img').first().css('background-image');
        bg = bg.replace(/url\(["']?/, '').replace(/["']?\)/, '');
        var img = new Image();
        img.onload = function () {
            var height = img.height / img.width * currentElement.width();
	    var width = currentElement.width();
	    if(height > $(window).height() * 70 / 100) {
	    	height = $(window).height() * 70 / 100;
		width = height * img.width / img.height;
	    }
            currentElement.height(height + 40);
	    currentElement.width(width);
            currentElement.find('.slides').height(height);
        };
        img.src = bg;
    });

    var giteMenu = $('#gite-menu');
    if (giteMenu.length) {
        if ($(window).width() >= 1101) {
            var giteDescription = $('#gite-description');
            giteMenu.css('left', giteDescription.offset().left + giteDescription.outerWidth());
            giteMenu.innerWidth($('#gite-main-row').width() * .25);
	    giteMenu.css('max-height', $(window).height() - 70);
        } else if ($(window).width() >= 601) {
            var giteDescription = $('#gite-description');
            giteMenu.css('left', giteDescription.offset().left + giteDescription.outerWidth());
            giteMenu.innerWidth($('#gite-main-row').width() * (5.0/12.0));
	    giteMenu.css('max-height', $(window).height() - 70);
        } else {
            giteMenu.css('left', 'auto');
            giteMenu.innerWidth('100%');
	    giteMenu.css('max-height', '');
        }
    }
}

$(document).ready(resizeCarousel);
$(document).ready(function() {
    $('.sidenav').sidenav();
});

setTimeout(function () {
    $('.slider').slider('next');
}, 2000);

var languages = {
	fr: "fr",
	en: "uk",
	nl: "nl"
};

var bookingWidget = $('#widget-produit-OSMB-62467');
if (bookingWidget.length) {
    bookingWidget.on("DOMSubtreeModified", removeNights);
    bookingWidget.on("DOMSubtreeModified", hookSearch);
    var widgetProduit = AllianceReseaux.Widget.Instance("Produit", {
        idPanier: "WXtZWVk",
        idIntegration: 299,
        langue: languages[window.location.href.split('/')[3]],
        ui: "OSMB-62467"
    });
    widgetProduit.PreApp("moteur.resultat.overlay", true);
    widgetProduit.PreApp("panier.target", "_blank");
    widgetProduit.Initialise();
}

function removeNights() {
    var label = gettext("Nights");
    if($('[label="' + label + '"]').length) {
        bookingWidget.off("DOMSubtreeModified", removeNights);
        $('[label="' + label + '"]').remove();
    }
}

var searchHooked = false;
function hookSearch() {
    var originalButton = $('.OsMoteurBtnRecherche');
    if(originalButton.length && !searchHooked) {
        searchHooked = true;
	var clonedButton = originalButton.clone();
	bookingWidget.off("DOMSubtreeModified", hookSearch);
        clonedButton.on('click', function(event) {
	    if(moment($('.OsMoteurInputDate').val(), "DD/MM/YYYY").day() != 6) {
	        $('#wrong-date-modal').modal('open');
	    } else {
	    	var event = document.createEvent('Event');
		event.initEvent('click', true, false);
		originalButton[0].dispatchEvent(event);
            }
	});
        originalButton.after(clonedButton);
	originalButton.css('display', 'none');
    }
}

$.each([4, 5, 6, 7], function () {
    var calendarWidget = $('#widget-produit-OSMB-62467-' + this);
    if (calendarWidget.length) {
        var widgetProduit = AllianceReseaux.Widget.Instance("Produit", {
            idPanier: "WXtZWVk",
            idIntegration: 299,
            langue: languages[window.location.href.split('/')[3]],
            ui: "OSMB-62467-" + this
        });
    	widgetProduit.PreApp("panier.target", "_blank");
        widgetProduit.Initialise();
    }
});

$(document).ready(function() {
  $('.modal').modal();
});

function initMap() {
  if(document.getElementById('map')) {
    var map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: 48.1207245, lng: -3.3900118},
      zoom: 9
    });
    var modified = false;
    var refitting = false;

    map.addListener('idle', function() {
      refitting = false;
    });
    refitBounds();


    var marker = new google.maps.Marker({
      map: map,
      draggable: false,
      animation: google.maps.Animation.DROP,
      position: { lat: 47.799756, lng: -4.36110020000001 }
    });



    map.addListener('bounds_changed', function() {
      if(!refitting) {
        modified = true;
      }
    });

    $(window).on('resize', function() {
      if(!modified) {
        refitting = true;
        setTimeout(refitBounds, 200);
      }
    });

    function refitBounds() {
      refitting = true;
      map.fitBounds({
        north: 48.9752581060293,
        east: -1.2758302814453373,
        south: 47.38114779399457,
        west: -4.667859090039087
      });
    }
  }
}

//Google analytics (from https://www.cnil.fr/fr/solutions-pour-la-mesure-daudience)

var tagAnalyticsCNIL = {}

tagAnalyticsCNIL.CookieConsent = function() {
    // Remplacez la valeur UA-XXXXXX-Y par l'identifiant analytics de votre site.
    var gaProperty = 'UA-29698182-1'
    // Désactive le tracking si le cookie d'Opt-out existe déjà .
    var disableStr = 'ga-disable-' + gaProperty;
    var firstCall = false;

    //Cette fonction retourne la date d'expiration du cookie de consentement

    function getCookieExpireDate() {
     // Le nombre de millisecondes que font 13 mois
     var cookieTimeout = 33696000000;
     var date = new Date();
     date.setTime(date.getTime()+cookieTimeout);
     var expires = "; expires="+date.toGMTString();
     return expires;
    }

    //Affiche une  bannière d'information en haut de la page
     function showBanner(){
        var bodytag = document.getElementsByTagName('body')[0];
        var div = document.createElement('div');
        div.setAttribute('id','cookie-banner');
        div.setAttribute('width','70%');
        // Le code HTML de la demande de consentement
        div.innerHTML =  '<div style="background-color:#fff;text-align:center;padding:5px;font-size:12px;border-bottom:1px solid #eeeeee;" id="cookie-banner-message" align="center">' + gettext('Ce site utilise Google Analytics. En continuant à  naviguer, vous nous autorisez à  déposer un cookie à  des fins de mesure d\'audience.') + ' <a href="javascript:tagAnalyticsCNIL.CookieConsent.showInform()" style="text-decoration:underline;">' + gettext('En savoir plus ou s\'opposer') + '</a>.</div>';
        // Vous pouvez modifier le contenu ainsi que le style
        // Ajoute la bannière juste au début de la page
        bodytag.insertBefore(div,bodytag.firstChild);
        document.getElementsByTagName('body')[0].className+=' cookiebanner';
        createInformAndAskDiv();
     }


    // Fonction utile pour récupérer un cookie à partir de son nom
    function getCookie(NameOfCookie)  {
        if (document.cookie.length > 0) {
            begin = document.cookie.indexOf(NameOfCookie+"=");
            if (begin != -1)  {
                begin += NameOfCookie.length+1;
                end = document.cookie.indexOf(";", begin);
                if (end == -1) end = document.cookie.length;
                return unescape(document.cookie.substring(begin, end));
            }
         }
        return null;
    }

    //Récupère la version d'Internet Explorer, si c'est un autre navigateur la fonction renvoie -1
    function getInternetExplorerVersion() {
      var rv = -1;
      if (navigator.appName == 'Microsoft Internet Explorer')  {
        var ua = navigator.userAgent;
        var re  = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
        if (re.exec(ua) != null)
          rv = parseFloat( RegExp.$1 );
      }  else if (navigator.appName == 'Netscape')  {
        var ua = navigator.userAgent;
        var re  = new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})");
        if (re.exec(ua) != null)
          rv = parseFloat( RegExp.$1 );
      }
      return rv;
    }

    //Vérifie la valeur de navigator.DoNotTrack pour savoir si le signal est activé et est à  1
    function notToTrack() {
        if ( (navigator.doNotTrack && (navigator.doNotTrack=='yes' || navigator.doNotTrack=='1'))
            || ( navigator.msDoNotTrack && navigator.msDoNotTrack == '1') ) {
            var isIE = (getInternetExplorerVersion()!=-1)
            if (!isIE){
                 return true;
            }
            return false;
        }
    }

    //Si le signal est à  0 on considère que le consentement a déjà  été obtenu
    function isToTrack() {
        if ( navigator.doNotTrack && (navigator.doNotTrack=='no' || navigator.doNotTrack==0 )) {
            return true;
        }
    }

    // Fonction d'effacement des cookies
    function delCookie(name )   {
        var path = ";path=" + "/";
        var hostname = document.location.hostname;
        if (hostname.indexOf("www.") === 0)
            hostname = hostname.substring(4);
        var domain = ";domain=" + "."+hostname;
        var expiration = "Thu, 01-Jan-1970 00:00:01 GMT";
        document.cookie = name + "=" + path + domain + ";expires=" + expiration;
    }

    // Efface tous les types de cookies utilisés par Google Analytics
    function deleteAnalyticsCookies() {
        var cookieNames = ["__utma","__utmb","__utmc","__utmt","__utmv","__utmz","_ga","_gat"]
        for (var i=0; i<cookieNames.length; i++)
            delCookie(cookieNames[i])
    }

    //La fonction qui informe et demande le consentement. Il s'agit d'un div qui apparait au centre de la page
    function createInformAndAskDiv() {
        var bodytag = document.getElementsByTagName('body')[0];
        var div = document.createElement('div');
        div.setAttribute('id','inform-and-ask');
        div.style.width= window.innerWidth+"px" ;
        div.style.height= window.innerHeight+"px";
        div.style.display= "none";
        div.style.position= "fixed";
	div.style.zIndex = "100000";
        // Le code HTML de la demande de consentement
        // Vous pouvez modifier le contenu ainsi que le style
        div.innerHTML =  '<div style="width: 300px; background-color: white; repeat scroll 0% 0% white;border: 1px solid #cccccc; padding :10px 10px; text-align:center; position: fixed; top:30px; left:50%; margin-top:0px; margin-left:-150px; opacity:1" id="inform-and-consent">' +
	    gettext('<div><span><b>Les cookies Google Analytics</b></span></div><br><div>Ce site utilise  des cookies de Google Analytics, ces cookies nous aident à identifier le contenu qui vous interesse le plus ainsi qu\'à  repérer certains dysfonctionnements. Vos données de navigations sur ce site sont envoyées à  Google Inc</div>') +
	    '<div style="padding :10px 10px;text-align:center;"><button style="margin-right:50px;text-decoration:underline;" name="' + gettext('S\'opposer') + '" onclick="tagAnalyticsCNIL.CookieConsent.gaOptout(); tagAnalyticsCNIL.CookieConsent.hideInform();" id="optout-button" >' + gettext('S\'opposer') + '</button><button style="text-decoration:underline;" name="' + gettext('Accepter') + '" onclick="tagAnalyticsCNIL.CookieConsent.hideInform()">' + gettext('Accepter') + '</button></div></div>';
        // Ajoute la bannière juste au début de la page
        bodytag.insertBefore(div,bodytag.firstChild);
    }



    function isClickOnOptOut( evt) {
        // Si le noeud parent ou le noeud parent du parent est la bannière, on ignore le clic
        return(evt.target.parentNode.id == 'cookie-banner' || evt.target.parentNode.parentNode.id =='cookie-banner'
        || evt.target.id == 'optout-button')
    }

    function consent(evt) {
        // On vérifie qu'il ne s'agit pas d'un clic sur la bannière
        if (!isClickOnOptOut(evt) ) {
            if ( !clickprocessed) {
                evt.preventDefault();
                document.cookie = 'hasConsent=true; '+ getCookieExpireDate() +' ; path=/';
                callGoogleAnalytics();
                clickprocessed = true;
                window.setTimeout(function() {evt.target.click();}, 1000)
            }
        }
    }


    // Tag Google Analytics, cette version est avec le tag Universal Analytics
    function callGoogleAnalytics() {
        if (firstCall) return;
        else firstCall = true;

(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

  ga('create', 'UA-29698182-1', 'auto');
  ga('send', 'pageview');
    }

    return {

        // La fonction d'opt-out
         gaOptout: function() {
            document.cookie = disableStr + '=true;'+ getCookieExpireDate() +' ; path=/';
            document.cookie = 'hasConsent=false;'+ getCookieExpireDate() +' ; path=/';
            var div = document.getElementById('cookie-banner');
            // Ci dessous le code de la bannià¨re affichà©e une fois que l'utilisateur s'est opposà© au dà©pot
            // Vous pouvez modifier le contenu et le style
            if ( div!= null ) div.innerHTML = '<div style="background-color:#fff;text-align:center;padding:5px;font-size:12px;\
            border-bottom:1px solid #eeeeee;" id="cookie-message"> Vous vous êtes opposé au dépôt de cookies de mesures d\'audience\
            dans votre navigateur </div>'
            window[disableStr] = true;
            clickprocessed = true;
            deleteAnalyticsCookies();
        },


         showInform: function() {
            var div = document.getElementById("inform-and-ask");
            div.style.display = "";
        },


         hideInform: function() {
            var div = document.getElementById("inform-and-ask");
            div.style.display = "none";
            var div = document.getElementById("cookie-banner");
            div.style.display = "none";
        },


        start: function() {
            //Ce bout de code và©rifie que le consentement n'a pas dà©jà  à©tà© obtenu avant d'afficher
            // la bannià¨re
            var consentCookie =  getCookie('hasConsent');
            clickprocessed = false;
            if (!consentCookie) {
                //L'utilisateur n'a pas encore de cookie, on affiche la bannià©re.
                //Si il clique sur un autre à©là©ment que la bannià©re on enregistre le consentement
                if ( notToTrack() ) {
                    //L'utilisateur a activà© DoNotTrack. Do not ask for consent and just opt him out
                    tagAnalyticsCNIL.CookieConsent.gaOptout()
                } else {
                    if (isToTrack() ) {
                        consent();
                    } else {
                        if (window.addEventListener) {
                          window.addEventListener("load", showBanner, false);
                          document.addEventListener("click", consent, false);
                        } else {
                          window.attachEvent("onload", showBanner);
                          document.attachEvent("onclick", consent);
                        }
                    }
                }
            } else {
                if (document.cookie.indexOf('hasConsent=false') > -1)
                    window[disableStr] = true;
                else
                    callGoogleAnalytics();
            }
        }
    }

}();

tagAnalyticsCNIL.CookieConsent.start();
